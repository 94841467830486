.DAttl1{
    color: #555;
font-size: 18px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.DAstdinf{
    color: #292D32;
text-align: right;
font-size: 12px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.11px;
}
.DAstdinf2{
    color: #292D32;
font-size: 12px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.11px;
}
.DAbox{
    box-shadow: 0px 7.853333473205566px 47.119998931884766px rgba(208, 208, 208, 0.50);
}
.DAstdbtn2{
    border-radius: 3.141px;
border: 0.785px solid #0E8E60;
background: #FFF;
height: 30px;

display: flex;
width: 72px;
padding: 3.141px 0px;
justify-content: center;
align-items: center;

color: #0E8E60;
font-size: 10.995px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.11px;
}
.DAstdbtn5{
    border-radius: 3.141px;
    /* border: 0.785px solid #0E8E60; */
    background: #FFF;
    height: 30px;
    
    display: flex;
    width: 72px;
    padding: 3.141px 0px;
    justify-content: center;
    align-items: center;
    
    color: #0E8E60;
    font-size: 10.995px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.11px;
}
.DAstdbtn3{
    border-radius: 3.141px;
border: 0.785px solid #0E8E60;
background: #0E8E60;
color: #FFF;
height: 30px;

display: flex;
width: 72px;
padding: 3.141px 0px;
justify-content: center;
align-items: center;


font-size: 10.995px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.11px;
}
.DAstdbtn4{
    border-radius: 3.141px;
/* border: 0.785px solid #0E8E60; */
background: #0E8E60!important;
color: #FFF;
height: 30px;

display: flex;
width: 72px;
padding: 3.141px 0px;
justify-content: center;
align-items: center;


font-size: 10.995px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.11px; 
}
#DAttl0{
    color: #0E8E60;
font-family: Poppins;
font-size: 35px;
font-style: normal;
font-weight: 500;
line-height: -92.404px; /* 206.87% */
}
#DAttl0_0{
    color: #0E8E60;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: -92.404px; /* 206.87% */  
}
.cd10{
    height: calc(100vh - 80px); 
    overflow-y: scroll;
  }
  
  
  .cd10::-webkit-scrollbar {
    width: 0.1em;
    /* background-color: #F5F5F5; */
  }
  /* .ipynb-renderer-root .rendered_html h1, .ipynb-renderer-root .text_cell_render h1 {
    color: #0E8E60!important;
    font-size: 200%;
    text-align: left;
    font-style: normal;
    font-weight: 400;
}
.ipynb-renderer-root .rendered_html h2, .ipynb-renderer-root .text_cell_render h2 {
    color: #0E8E60!important;
    font-size: 170%;
    font-style: normal;
    font-weight: 400;
}
.ipynb-renderer-root .rendered_html :link {
    font-family: sans-serif;
    font-size: 100%;
    color: #0E8E60!important;
    text-decoration: underline;
} */
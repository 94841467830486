.upldttl{
    color: #42505C;
font-size: 35px;
font-family: Poppins;
font-style: normal;
font-weight: 600;
line-height: 70.833px;
}
.upldbn{
    color: #FFF;
text-align: center;
font-size: 13.311px;
font-family: Poppins;
font-style: normal;
font-weight: 700;
line-height: 26.622px;
letter-spacing: 0.238px;
width: 96.979px;
height: 45.637px;
flex-shrink: 0;
}
.p-calendar-w-btn .p-datepicker-trigger.p-button {
    background-color: #0E8E60 !important;
    border: 0 none;
}
.custom-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  font-style: poppins !important;
  border: 1px solid !important;
  /* background-color: blue !important; */
  color: white !important;

  border-radius: 50% !important;
border: 0.785px solid #EEE !important;
background-color: #0e8e60 !important;
display: inline-flex !important;
padding: 4.712px 7.068px !important;
flex-direction: column !important;
justify-content: center !important;
align-items: center !important;
}
.pge{
  height: 40px;
  border-radius: 3.141px !important;
  border: 0.785px solid #EEE !important;
  background-color: #F5F5F5 !important;
  display: inline-flex !important;
  padding: 4.712px 7.068px !important;
  justify-content: center !important;
  align-items: center !important;
}
.p-link {
  font-size: 1rem;
  font-family: poppins;
  border-radius: 4px;
}
.ttl{
  color: #555;
font-size: 35px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: 72.404px;
}
.ttl_0{
  color: #555;
  font-size: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 72.404px;
}
.cd10{
  height: calc(100vh - 80px); 
  overflow-y: scroll;
}


.cd10::-webkit-scrollbar {
  width: 0.1em;
  /* background-color: #F5F5F5; */
}
.custom-datatable {
  width: 100%; /* Set the desired width */
}

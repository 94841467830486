.cmttl{
    color: #0E8E60;
font-size: 35px;
font-family: Poppins;
font-style: normal;
font-weight: 600;
line-height: 70.833px;
}
.cmttl_0{
    color: #0E8E60;
font-size: 25px;
font-family: Poppins;
font-style: normal;
font-weight: 600;
line-height: 70.833px;
}
.custom-expand-icon{
    border: 1px solid;
    background-color: #0E8E60;
    color: #fff;
    border-radius: 50%;
    /* width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center; */
    padding: 8px;
}
.acrhdr{
    color: #0E8E60;
font-size: 20px;
font-family: Poppins;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.acrbdyttl{
    color: #0E8E60;
font-size: 17px;
font-family: Poppins;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.255px;
}
.acrbdybtn{
    color: #FFF;
font-size: 10.995px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.11px;
display: flex;
width: 96px;
padding: 3.141px 9.424px;
justify-content: center;
align-items: center;
gap: 7.853px;
border-radius: 3.141px;
border: 0.785px solid #0E8E60;
background: #0E8E60;
}

.card {
    height: calc(100vh - 80px); 
    overflow-y: scroll;
  }
  
  .card::-webkit-scrollbar {
    width: 0.5em;
    /* background-color: #F5F5F5; */
  }
  
  /* .card::-webkit-scrollbar-thumb {
    background-color: #000000;
  } */
  
 .p-accordion-tab {
    
    /* margin-top: 10px; */
  }
  
  
.accordion-container{
    /* border-radius: 22px;
background: #FFF;
box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15); */
height: 50px;
flex-shrink: 0;
padding-left: 2px;
padding-right: 2px;
}
/* .accordion-tab{
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15);
    flex-shrink: 0;
    padding-left: 2px;
    padding-right: 2px;
} */
.accordion-tab{
    border-radius: 12px;
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15);
    background: #FFF;
z-index: 5;
}
.body {
  height: 90%;
}

.p-password-input{
  width: 100%;
}
.p-inputgroup-addon{
  font-size: 14px;
font-family: Poppins;
line-height: 28px;
letter-spacing: 0.25px;

}

/* .p-dialog .p-dialog-header-icons {
  display: none;
  align-items: center;
  align-self: flex-start;
  flex-shrink: 0;
} */
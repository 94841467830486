.admin_dashboard_bigscreen_sidebar {
    width: 18%;
    border: 1px solid rgb(250, 249, 249);
    box-shadow: 2px 0px 5px rgb(207, 210, 207);
    position: fixed; /* Fix the sidebar position */
    top: 0; /* Attach it to the top of the viewport */
    bottom: 0; /* Stretch it to the bottom of the viewport */
    overflow-y: auto; /* Add vertical scroll if the content exceeds the viewport height */
    height: 100vh;
  }
  .shop_details_heading_text{
    font-size: 23px;
    color: #0E8E60;
    margin-top: 13px;
    margin-bottom: 2px;
  }
  .edit_input_tag{
border: 1px solid #0E8E60;
max-width: fit-content;

  }
  .some>.p-inputswitch .p-inputswitch-slider {
 
     border-radius: 0;
     height: 1.6rem;
     
}
.some>.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #0E8E60 !important;
  border-radius: 0 !important;
 
  
}
.some>.p-inputswitch .p-inputswitch-slider:before {
  background: #fff;
  width: 1.5rem;
  height: 1.5rem;
  left: -1px;
  margin-top: -0.75rem;
  border-radius: 0 !important;
  transition-duration: 0.2s;
 
}
  .update_dialog_box{
    width: 50%;
    height: auto;
  }
  .info_dialog_box{
    width: 50%;
    height: auto;
  }
  /* .showform{
    display: none;
  } */
  .tag_holder{
    color: rgb(0, 0, 0);
    font-weight: 500;
  }
.btn_size_change{
  height: 28px;
}
.icons_cl{
  cursor: pointer;
}
  .Shop_approval_above_div{
    margin-left: 17%;
  }
  .admin_dashboard_bigscreen_content {
    margin-left: 5%; /* Adjust content margin to accommodate the fixed sidebar */
    width: 100%;
    border: 1px solid rgb(255, 255, 255);
    height: 100%;
    overflow-y: auto; /* Add vertical scroll if the content exceeds the viewport height */
  overflow-x: hidden;
  }
  .header_approval{
    padding-bottom: 20px;
   padding-left: 23px !important;
   padding-right: 8px !important;
   margin-left: 16% !important;
    width: 81% !important;
    border: 2px solid rgb(255, 255, 255);
  }
  .admin_dashboard_smallscreen_logo_div{
  display: none !important;
  }
  .search_bar_input_smallscreen{
    display: none;
  }
  .admin_dashboard_bigscreen_sidebar::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
  
  .admin_dashboard_bigscreen_sidebar::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
  .admin_dashboard_bigscreen_logo_div{
      display: flex;
      justify-content: center;
  }
  .admin_dashboard_bigscreen_logo_div img{
      color: #0E8E60;
  font-family: Inter;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  }
  .smallscreen_pi_bars{
  display: none;
  }
  .admin_dashboard_bigscreen_profile_div{
      align-items: center;
      position: relative;
      gap: 10%;
  background-color: #fff;
  border: none;
  border-radius: 6px;
      margin-left: 13%;
  }
  .admin_dashboard_bigscreen_profile_avatar_div{
      position: absolute;
      top: -5px;
      left: 0;
      
  }
  .admin_dashboard_bigscreen_profile_username_div{
      margin-left: 25%;
  }
  
  .accordian_name{
      color: #0E8E60;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  }
  .accordian_name0{
    color: #0E8E60;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  }
  .accordian_email{
      color: rgba(0, 0, 0, 0.85);
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  }
  .accordion-content ul,
  .accordion-content-profile ul {
    display: flex;
    flex-direction: column;
    gap: 7%;
  }
  
  .accordion-content ul li,
  .accordion-content-profile ul li{
      /* color: black; */
     padding: 3px;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: -22%;
  margin-bottom: 2%;
  
  }
  
  
  .accordion-content {
    width: 100% !important;
  }
  
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #cff6e7 !important;
    border-color: #d8faef !important;
    color: #0E8E60 !important;
  }
  
  .profile-image{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: 29px;
  }
  
  
  .Accordian {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
    }
  
    
    .accordion {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      border: 1px solid #ffffff;
      background: var(--white-white-10, #FEFFFF);
      overflow: hidden;
      margin-bottom: 10px;
      
    }
    
    .accordion-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      flex-shrink: 0;
      padding: 0 10px;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
      transition: background-color 0.3s;
    }
    .accordion{
      color: black;
    }
    .greencolor{
      color:#0E8E60 !important;
    }
    .accordion.open .accordion-header {
      background-color: #0E8E60;
      color:#fff;
    }
    
    .accordion-content {
      width: 150px;
      margin-left: 0px;
      margin-top: 15px;
      background-color: white;
      color: black;
    }
    
    .arrow {
      font-size: 16px;
    }
    
    .up {
      transform: rotate(180deg);
      color: #ffffff;
    }
    .pi-chart-pie {
      margin-left:-20px;
    }
    .pi-check-circle{
      margin-left:-0.4rem;
    }
    .arrow-container {
      display: flex;
      align-items: center;
      margin-left: 20px;
    }
   .arrow-container.arr2{
    display: flex;
    align-items: center;
    margin-left: 33px !important;
   }
    /* ... (existing styles) ... */
  
  .accordion-header {
      /* ... (existing styles) ... */
      font-family: Raleway, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }
    
    .accordion-content {
      /* ... (existing styles) ... */
      color: rgba(0, 0, 0, 0.70);
  text-align: justify;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 187.5%
    }
    .accordion-content-profile{
      color: rgba(0, 0, 0, 0.70);
      text-align: justify;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 187.5%
    }
    .accordion-content-profile {
      width: 150px;
      margin-left: 17px;
      margin-top: 7px;
      background-color: white;
      color: black;
    }
  .admin_dashboard_bigscreen_dashboard_button_div{
    width: 216px;
  height: 63px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 0.5px solid #ffffff;
  background: #edffef;
  color: #3b3838;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  display: flex;
  /* margin-left: 13%; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5%;
  margin-top: 10%;
  
  }
  .admin_dashboard_bigscreen_dashboard_button_div2{
    width: 216px;
    height: 63px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 0.5px solid #0E8E60;
    background: #0E8E60;
    color: #FFF;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    display: flex;
    /* margin-left: 13%; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5%;
     margin-top: 10%; 
  }
  .registration_button_accordian_div{
    width: 200px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 5%;
  }
  .accordion2{
    display: flex;
    flex-direction: column;
    gap: 10%;
    margin-left: 0%;
  }
  .accordion-header2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    flex-shrink: 0;
    padding: 0 10px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s;
    
  }
  .accordion-header3{
    display: flex;
   
   
  
    flex-shrink: 0;
  
   
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s;
  }
  .accordian_name2{
    display: flex;
    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    gap: 6%;
    margin-left: 27px;
  }
  .accordian_name3{
    display: flex;
    color: rgb(0, 0, 0);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    gap: 7%;
    margin-left: 28px;
    
  }
  .accordion2.open .accordion-header2 .accordian_name2{
    color: #ffffff !important;
   
  }
  .accordion3.open .accordion-header3 .accordian_name3{
    color: #ffffff !important;
   
  }
  .big_screen_button{
    display: flex;
   
    border: none;
    background-color: #FEFFFF;
    justify-content: center;
    
  width: 152px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: rgba(0, 0, 0, 0.75);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 0%;
  margin-top: 10%;
  cursor: pointer;
  gap: 7%;
  }
  .Approval_button_accordian_div{
    width: 200px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 5%;
    margin-left: 0%;
  }
  .admin_content_logo_div{
    height: 120px;
   
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .admin_content_logo_div img{
    height: 30px;
    width: 220px;
    color: #0E8E60;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: -6%;
  }
  
  .search_bar_input{
  
  
  flex-shrink: 0;
  border-radius: 10px;
  border: 0.3px solid rgba(0, 0, 0, 0.35);
  background: #FFF;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.03);
  }
  
  .customers_div{
    width: 258.25px;
  height: 149px;
  flex-shrink: 0;
  border-radius: 15px;
  border: 0.5px solid #cbe6d0;
  background: #FFF;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.05);
  align-items: center;
  justify-content: space-around;
  }
  .admin_content_shops_div{
    gap: 2%;
    padding-left: 1%;
    overflow-x:hidden !important;
  }
  .forsmallscreen{
  width: fit-content !important;
    gap: 4%;
  }
  .forsmallscreen2{
   
    gap: 2%;
  }
  .forsamllscreen2{
    gap: 2%;
  }
  .customers_content{
    color: rgba(0, 0, 0, 0.85);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 111px;
  height: 19px;
  
  }
  
  .customers_numbers{
  
  
  color: rgba(0, 0, 0, 0.85);
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: -5%;
  margin-bottom: -5%;
  }
  
  .customers_small_text{
    
    color: rgba(0, 0, 0, 0.85);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  
  .admin_content_text_div{
    gap: -10%;
  }
  .abc >.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px; /* Adjust the width as per your design */
    /* Add any other styles you need */
  }
  
  .admin_dashboard_main_div {
    margin-left: 250px; /* Adjust margin to accommodate the fixed sidebar */
    /* Add any other styles you need */
  }
  .nav_div_sl{
    margin-left: 17%;
  }
  .tabcrslbox{
    height: 370px;
    border-radius: 20px;
  border: 2px solid #d9d9d9;
  background: #FFF;
  }
  /* .splide__pagination {
    bottom: 1em;
    left: 0;
    padding: 0 1em;
    position: absolute;
    right: 0;
    z-index: 1;
    top: 23em;
    border: 1px solid red;
  } */
  
  .crscrtd{
      color: rgba(0, 0, 0, 0.70);
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 156%; /* 31.2px */
  }
  .crscrtd0{
    color: rgba(0, 0, 0, 0.70);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 156%; /* 18.72px */
  }
  .crscrt{
      color: #0E8E60;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 156%;
  }
  .crscrt0{
    color: #0E8E60;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 156%;
  }
  .crslvl{
      color: #000;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 67.5px */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: "100%",
  }
  .crslvl:hover{
    overflow: visible !important;
  }
  .crslvl0{
    color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 32px */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  .crsname{
      color: #0E8E60;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  }
  .crsname0{
    color: #0E8E60;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  .crsdes{
      color: #000;
  text-align: justify;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 33px */
  }
  .crsdes0{
    color: rgba(0, 0, 0, 0.70);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 165%; /* 19.8px */
  text-align: start;
  }
  .bnbtn{
    color: #FFF;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 3px;
  background: #0E8E60;
  border: 0;
  }
  .bnprce{
    color: #0E8E60;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.038px;
  }
  .bnprce0{
    color: #0E8E60;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.038px;
  }
  .crswrating{
      color: #0E8E60;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.067px;
  }
  
  .crsrtng{
      color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.026px;
  margin-top: 8px;
  }
  
  .crssidedivaa{
      border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .crssidediv0{
    background: #FFF;
  
  }
  .crssideprice{
      color: #0E8E60;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.077px;
  }
  .firststrike{
      color: #BDB9B9;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.038px;
  }
  .crsforpri{
      text-align: start;
  
  }
  .percent{
      color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.043px;
  margin-left: 5px;
  
  }
  .off{
      color: #0E8E60;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.043px;
  margin-left: 5px;
  }
  .timer{
      color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.043px;
  margin-left: 5px;
  
  }
  .crsbuynowbtn{
      border-radius: 15px;
  background: #0E8E60;
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 0;
  display: flex;
    justify-content: center;
    align-items: center;
  }
    .crsadtocrtbtn{
      display: flex;
      justify-content: center;
      align-items: center;
      color: #0E8E60;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 15px;
  border: 1px solid #0E8E60 !important;
  background: #FFF;
  
    }
    .crsadtocrtbtn0{
      border-radius: 3px;
  border: 0.2px solid #0E8E60;
  background: #FFF;
  color: #0E8E60;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
    }
    .crsbynwbtn0{
       border-radius: 3px;
  border: 0;
  background: #0E8E60;
  color: #FFF;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
    }
    .crsquote{
      color: #BDB9B9;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.038px;
  text-align: center;
    }
    .individualheader{
      color: #000;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 54px */
    }
    .individualheader0{
      color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
    }
    .individualheader2{
      color: #0E8E60;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
    }
    .individualheader20{
      color: #0E8E60;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
    }
    .indiduallist1{
      color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  margin-bottom: 15px;
    }
    .indiduallist10{
      color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
    }
    .indiduallist2{
      color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  margin-bottom: 20px;
    }
    .indiduallist20{
      color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
    }
    .listgreentext{
      color: #0E8E60;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
    }
    .listgreentext0{
      color: #0E8E60;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: start;
    }
    /* @media only screen and (max-width: 750px) {
      .p-tabview-nav {
        display: flex;
        justify-content: space-around;
        align-items: center;
        list-style-type: none;
        flex: 1 1 auto;
        padding-right: 0px;
    }
      .p-tabview .p-tabview-nav .p-tabview-ink-bar {
          z-index: 1;
          display: block;
          position: absolute;
          bottom: 0;
          height: 2px;
          background-color: #0E8E60 !important;
          transition: 500ms ;
        
          padding-left: 0 !important;
          margin-left: 0 !important;
      }
      .p-tabview .p-tabview-nav li .p-tabview-nav-link  {
          border-color: transparent transparent transparent transparent;
          background: #ffffff;
          color: #000;
          padding-left: 0;
        
          font-weight: 500;
          border-top-right-radius: 0px;
          border-top-left-radius: 0px;
          transition: box-shadow 0.2s;
          margin-left: 5px !important;
          width: 100% !important;
          text-align: center !important;
      }
      .p-unselectable-text.p-tabview-selected.p-highlight {
        width: 100%;
        padding-left: 0;
        padding-right: 0 !important;
        margin-right: 0 !important;
      }
      .p-unselectable-text {
        width: 100% !important;
        padding-left: 0;
      }
      .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        padding: 10px;
        border-color: #fff !important;
        color: #0E8E60 !important;
        margin-left: 5px !important;
        width: 100% !important;
    }
    #pr_id_6_header_0,#pr_id_6_header_1, #pr_id_6_header_2, #pr_id_6_header_3 {
      background: #ffffff !important;
      padding: 10px;
      border-color: #fff !important;
      color: #0E8E60 !important;
      margin-left: 5px !important;
      width: 100% !important;
  }
  } */
    .individualtabhdr{
      color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: auto;
    }
    .individualtabhdr0s{
      color: #0E8E60;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
    }
    .custom-expand-icon{
      border: 1px solid #0E8E60;
      background-color: #FFF;
      color: #0E8E60;
      border-radius: 50%;
      /* width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center; */
      padding: 4px;
      margin-top: -8px;
      position: absolute;
      right: 0.5em;
      top: 40%;
  }
  .acrhdr{
      color: #0E8E60;
  font-size: 16px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }
  .acrhdr0{
    color: #0E8E60;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .acrbdyttl{
    color: #888888;
  font-size: 14px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.255px;
  }
  .acrbdybtn{
    color: #FFF;
  font-size: 10.995px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.11px;
  display: flex;
  width: 96px;
  padding: 3.141px 9.424px;
  justify-content: center;
  align-items: center;
  gap: 7.853px;
  border-radius: 3.141px;
  border: 0.785px solid #0E8E60;
  background: #0E8E60;
  }
  .p-accordion-tab {
      
    margin-top: 10px;
  }
  
  
  .accordion-container{
    /* border-radius: 22px;
  background: #FFF;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15); */
  /* height: 50px; */
  /* flex-shrink: 0; */
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0;
  padding-bottom: 0;
  }
  /* .accordion-tab{
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15);
    flex-shrink: 0;
    padding-left: 2px;
    padding-right: 2px;
  } */
  .accordion-tab{
    /* border: 1px solid red; */
    border-radius: 12px;
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15);
    background: #FFF;
  /* z-index: 5; */
  }
  /* .accordion-container .p-accordion-header {
    height: 50px; 
    overflow: hidden;
  } */
  /* .p-accordion .p-accordion-header .p-icon {
    margin-top: -8px;
    position: absolute;
    right: 0.5em;
    top: 50%;
  } */
  .individualrequirement{
    color: #0E8E60;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .individualrequirement0{
    color: #0E8E60;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .individualrequirementlist{
    color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  margin-bottom: 10px;
  }
  .individualrequirementlist0{
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
  margin-bottom: 5px;
  }
  .individualrequirementdesc{
    color: #000;
  text-align: justify;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 38.5px */
  }
  .individualrequirementdesc0{
    color: #000;
  text-align: start;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 38.5px */
  }
  .rolesheading{
    color: #000;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }
  .seemorbtn{
    color: rgba(14, 142, 96, 0.75);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 24.3px */
  letter-spacing: 0.18px;
  }
  .seemorbtn:hover{
    text-decoration: underline;
    cursor: pointer;
    text-align: right;
    
  }
  .individualcrslhdr{
    color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 44.5px; /* 123.611% */
  }
  .individualcrslhdr0{
    color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 44.5px; /* 123.611% */
  }
  .individualcrslhdr2{
    color: #0E8E60;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 44.5px;
  }
  .individualcrslhdr20{
    color: #0E8E60;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 44.5px;
  }
  .individualrequirementreviews{
    color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .individualrequirementreviews0{
    color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .individualrequirementreviews2{
    color: #0E8E60;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .individualrequirementreviews20{
    color: #0E8E60;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .individualrating{
    color: #000;
  font-family: Poppins;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .individualrating0{
    color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .individualratingdesc{
    color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }
  .individualratingdesc0{
    color: #000;
  font-family: Poppins;
  font-size: 7px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }
  .p-slider .p-slider-handle {
    position: absolute;
    cursor: grab;
    touch-action: none;
    display: none;
    z-index: 1;
  }
  .p-slider.p-slider-horizontal {
    height: 0.176rem;
  }
  .p-slider .p-slider-range {
    background: #0E8E60;
  }
  .individualpercnt{
    color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  }
  .individualpercnt0{
    color: #000;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  }
  .individualrequirementrewieshd{
    color: #0E8E60;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .individualrequirementrewieshd0{
    color: #0E8E60;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .individualreviewname{
    color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .individualreviewname0{
    color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  }
  .individualdate{
    color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: start;
  }
  .individualreviewtext{
    color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  }
  .rprtbtn{
    color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  }
  .insthed{
    color: #0E8E60;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  }
  .insnm{
    color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.077px;
  }
  .insrating{
    color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.048px;
  }
  .insrating0{
    color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.048px;
  }
  .insdesc{
    color: #000;
  text-align: justify;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 38.5px */
  letter-spacing: 0.24px;
  }
  .insdesc0{
    color: #000;
  text-align: justify;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 38.5px */
  letter-spacing: 0.24px;
  }
  
  /*for video**/
  
  /* Adding Google Font  */
  @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&display=swap');
  
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }
  
  .custom-video {
      position: relative;
      top: 0;
      /* width: 100%; */
      /* height: 100vh; */
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
  
      /* background-color: #00808097; */
  }
  
  
  .custom-video__container {
      position: relative;
      top: 0;
      /* width: 32vw;
      height: auto; */
      /* margin: 1em; */
      font-family: 'Oswald', sans-serif;
    
  
  }
  
  
  
  .custom-video__video {
    border: 0px !important;
      /* border-radius: 0.2em; */
      cursor: pointer;
  
  }
  
  
  .custom-video__control {
      position: absolute;
      top: 40%;
      left: 42%;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      padding: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 1em;
      font-weight: 400;
      width: 3em;
      height: 3em;
      white-space: nowrap;
      line-height: 0;
      /* border: 1px solid red; */
  }
  
  video::-webkit-media-controls {
      position: relative;
      z-index: 1;
  }
              
          
  
  
  @keyframes progress {
    from{
      transform: scaleX(0);
    }
    to{
      transform: scaleX(1);
    }
  }
  .progress{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 10px;
    background: #0E8E60;
  
    transform-origin: left;
    animation: progress auto linear;
    /* animation-timeline: scroll(); */
    animation: scroll() 2s ease infinite;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon{
  /* transform: rotate(90) !important;
  color: #FFF;
      border-radius: 50%;
      height: 20px;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
      width: 20px;
      padding: 3px; */
      border: 0;
      background-color: #0E8E60;
      color: #fff;
      border-radius: 50%;
      padding: 3px;
  
      top: 45%;
  }
  .ttlacrdbox{
    text-align: start;
    color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    height: 50px !important;
  }
  
  .insphoto img{
    width: 100%;
    height: 100%;
  }
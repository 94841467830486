.crcshdr{
    color: #0E8E60;
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.crcshdr0{
    color: #0E8E60;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.crcssbhdr{
    color: #0E8E60;
font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.crcssbhdr0{
    color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
#crcslbl{
    color: #0E8E60;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
#crcslbl0{
    color: #0E8E60;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.sv_nxtbtn{
    color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 5px;
background: #0E8E60;
border: 0;
}
.sv_nxtbtn0{
    color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
border-radius: 5px;
background: #0E8E60;
border: 0;
width: 107px;
height: 35px;
flex-shrink: 0;
}
.otcrsbtn{
    color: #0E8E60;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.no1{
    color: #555;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.otcrsbtn0{
    color: #0E8E60;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.no10{
    color: #555;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.addbtn{
    width: 95px;
height: 40px;
flex-shrink: 0;
border-radius: 10px;
background: #0E8E60;
color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 100% */
}
.addbtn0{
    width: 50px;
height: 20px;
flex-shrink: 0;
border-radius: 3px;
background: #0E8E60;
color: #FFF;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 100% */
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #81c390;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #0E8E60 !important;
}
.p-chips .p-chips-multiple-container {
    padding: 0.5rem 1rem;
    width: 100% !important;
}
.p-chips .p-chips-multiple-container:focus {
   box-shadow: none !important;
   border: #0E8E60 !important;

}
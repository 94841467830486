.bghd1{
    color: #000;
font-family: Poppins;
font-size: 46px;
font-style: normal;
font-weight: 600;
line-height: 120%;
margin-left: 6vw;
margin-top: 3vw;
}
.bgcrtbtn{
    margin-left: 6vw;

    border-radius: 80.67px;
background: #0E8E60;
width: 195.625px;
height: 64.536px;
flex-shrink: 0;
color: #EFEFEF;
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.bghd2{
    color: #263238;
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 140%;
position: absolute;
left: 400px;
bottom: 600px;
}
.bghd2_1{
    color: #263238;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
   
}
.bghd3{
font-family: Poppins;
font-size: 40px;
font-style: normal;
font-weight: 600;
line-height: 140%;
}
.bghd3_0{
    font-family: Poppins;
font-size: 30px;
font-style: normal;
font-weight: 600;
line-height: 140%;
}
.ctgies{
    color: #0E8E60;
font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.ctgies_0{
    color: #0E8E60;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.bgsaabtn{
    border-radius: 30px;
background: #0E8E60;
color: #FFF;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: 160%;
}
.splide__arrow--next {
    background-color: #0E8E60 !important;
}
.splide__arrow--prev {
    background-color: #fff !important;
}
.splide__pagination__page {
    background: #0E8E60 !important;
    border: 0;
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    margin: 3px;
    opacity: .7;
    padding: 0;
    position: relative;
    transition: transform .2s linear;
    width: 8px;
  }
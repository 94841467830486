.footer {
  background-color: var(--green-500);
}

.footer-font-size {
  font-size: 14px;
}
.p-button-icon-only.p-button-rounded {
  height: 28px !important;
  width: 28px !important;
}

@media screen and (max-width: 768px) {
  .p-button-icon-only.p-button-rounded {
    height: 45px !important;
    width: 45px !important;
  }
}

.text_font {
  font-family: "Poppins";
}

.sidebar {
  height: 90%;
  min-height: 89vh;
}
.navbar {
  height: 10%;
  min-height: 9vh;
}

.coursename {
  font-weight: 600;
  font-family: "Poppins";
  font-size: 15px;
}
.price {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.0024em;
  text-align: left;
  color: #0e8e60;
}
.fulldetails.p-button-text {
  color: #0e8e60;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.0024em;
  text-align: left;
}
.fulldetails.p-button {
  width: 105px;
  font-size: 20px;
}
.fulldetails > .p-button-label {
  font-size: 13px;
  width: 100%;
}
.buttoncontainercourse {
  position: absolute;
  bottom: -25px;
}

@media screen and (max-width: 768px) {
  .coursename {
    font-size: 12px;
    font-family: "Poppins";
  }
  .buttoncontainercourse {
    position: absolute;
    bottom: 1px;
  }
  .frameworkcss {
    font-size: 8px;
    height: 20px;
    padding: 4px;
  }
  .price {
    font-size: 10px;
  }
  .fulldetails.p-button-text {
    color: #0e8e60;
    font-family: Poppins;
    font-size: 5px;
    font-weight: 600;

    letter-spacing: -0.0024em;
    text-align: left;
  }
  .fulldetails.p-button {
    width: 65px;
    font-size: 10px;
  }
  .fulldetails > .p-button-label {
    font-size: 8px;
    width: 100%;
  }
}



.custom-accordion .p-accordion-header {
  /* height: 50px; 
  overflow: hidden; */
  padding: 0;
  /* border: 1px solid red; */
}
.p-accordion {
  /* border: 1px solid red; */
  
}
/* .p-accordion .p-accordion-header .p-accordion-header-link {
  padding-right: 1rem;
  border: 0 none;
  font-family: "Poppins";
  color: var(--primary-color);
  background: #fff;
  font-weight: 500;
  border-radius: 4px;
  transition: none;
} */
.p-accordion-header-text {
  line-height: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.5rem;
  border: 0 none;
  font-family: "Poppins";
  color: var(--primary-color);
  background: #fff;
  font-weight: 500;
  border-radius: 4px;
  transition: none;
  /* clear: both; */
  display: flex;
  gap: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.p-megamenu-vertical .p-megamenu-root-list>.p-menuitem-active>.p-megamenu-panel {
  left: 0%;
  /* position: relative; */
}
.p-megamenu {
  margin-left: -15px;
  padding: 0;
  background: none;
  color: none;
  border: none;
  border-radius:0;
  margin-top: -15px;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #0e8e60;
  margin-left: 0.5rem;
}
.p-megamenu .p-menuitem-link {
  padding: 1rem 0.5rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  -webkit-user-select: none;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding:0rem 0.5rem;
  color: rgba(0, 0, 0, 0.6);
  background: #fff;
  font-weight: 400;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
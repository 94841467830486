.crd2{
    color: #333;
font-size: 26.921px;
font-family: Poppins;
font-style: normal;
font-weight: 600;
line-height: 100%;
letter-spacing: -0.269px;
}
.crd1{
    color: #ACACAC;
font-size: 11.778px;
font-family: Poppins;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.118px;
}
.crd3{
    color: #000;
font-size: 16px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: 100%;
letter-spacing: -0.16px;


}
.sectionshddr{
    color: #0E8E60;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.crd4{
    color: #000;
font-size: 17.051px;
font-family: Poppins;
font-style: normal;
font-weight: 300;
line-height: 100%;
letter-spacing: -0.171px;
}
.crd5{
    color: #666278;
font-size: 10px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
/* border: 1px solid red;
max-width: 150px; */
}
svg {
    width: 100%;
    height: 100%;
   
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Poppins:wght@200;300;400;500;700&family=Public+Sans:wght@200;500&family=Roboto:ital,wght@1,100&display=swap");

@import url("primeicons/primeicons.css");
@import url("./assets/theme.css");
@import url("primereact/resources/primereact.min.css");
@import url("/node_modules/primeflex/primeflex.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins";
}

@media screen and(min-width:2000px) {
  .apple {
  }
}
.h-40 {
  height: 40px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-checkbox {
    .p-checkbox-box.p-highlight {
      background-color: #0E8E60;
      border-color: #0E8E60; 
    }
    .p-checkbox-box:hover {
        background-color: #0E8E60; 
        border-color: #0E8E60; 
      }
    .p-checkbox-icon {
      color: white; 
    }
  }
  .fltrbx{
    color: var(--gray-300, #A1A9B8);
font-size: 14.839px;
font-family: Poppins;
font-style: normal;
font-weight: 400;
line-height: 21.198px;
    border-radius: 6.359px;
background: var(--white, #FFF);
box-shadow: 0px 0px 0px 1.0599077939987183px rgba(104, 113, 130, 0.16), 0px 1.0599077939987183px 2.1198155879974365px 0px rgba(0, 0, 0, 0.06);
  }
  .flrtxt{
    color: #292D32;
font-size: 12.995px;
font-weight: 500;
line-height: normal;
letter-spacing: -0.11px;
/* white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis; */

  }
 /* .flrtxt:hover{
  overflow: visible;
 } */
 .p-menu {
  padding: 0.5rem 0;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 16.5rem !important;
}
.custom-menu-label {
  color: #0E8E60;
  color: #0E8E60;
font-size: 18px;
font-family: Poppins;
font-style: normal;
font-weight: 400;
line-height: normal;

}
.minuhdr{
  color: #0E8E60;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 24px */
}
.minuemail{
  color: #263238;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: 100%; /* 16px */
}
.edithddr{
  color: #0E8E60;
font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.edithddr0{
  color: #0E8E60;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.rwndcrcl{
  width: 160px;
height: 160px;
display: flex;
justify-content: center;
align-items: center;
flex-shrink: 0;
fill: #FFF;
border: 1px solid #0E8E60;
border-radius: 50%;
}
.rwndcrcl0{
  width: 120px;
height: 120px;
flex-shrink: 0;
fill: #FFF;
border: 1px solid #0E8E60;
border-radius: 50%;
}
.pfldesc{
  color: #000;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.pfldesc0{
  color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.lebl{
  color: #0E8E60;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.upetbtn{
  border-radius: 5px;
background: #0E8E60;
width: 150px;
border: 0;
height: 50px;
flex-shrink: 0;
color: #FFF;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
/* .ui-progress-spinner {
  &.custom-spinner {
    .ui-progress-spinner-circle {
      animation: my-animation 1s linear infinite;
    }
  }
}

@keyframes my-animation {
  from {
    stroke: #0E8E60;
  }
  to {
    stroke: #0E8E60;
  }
} */
.p-progress-spinner-circle {

  stroke: #0E8E60 !important;
}
.ttlpg{
  color: #0E8E60;
  font-size: 32px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 72.404px;
}
.upldttl{
    color: #42505C;
font-size: 35px;
font-family: Poppins;
font-style: normal;
font-weight: 600;
line-height: 70.833px;
}
.PhoneInputInput {
    flex: 1 2;
    min-width: 0;
    height: 3rem;
    border: 1px solid rgba(0, 0, 0, 0.38);
}
/* .PhoneInputInput:focus{
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.38) !important;
} */
.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: 0 !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
    /* margin-right: var(--PhoneInputCountrySelect-marginRight); */
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-right: 0px;
}
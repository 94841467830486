#ttl0{
    color: #0E8E60;
font-family: Poppins;
font-size: 35px;
font-style: normal;
font-weight: 500;
line-height: -100.404px; /* 206.87% */
}

.ttl1{
    color: #555;
font-size: 18px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.stdinf{
    color: #292D32;
text-align: right;
font-size: 11.995px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.11px;
}
.stdinf5{
    color: #292D32;
    text-align: left;
    font-size: 11.995px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.11px;
}
.stdinf2{
    color: #292D32;
font-size: 11.995px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.11px;
}
.stdbtn{
    border-radius: 3.141px;
border: 0.785px solid #0E8E60;
background: #FFF;
height: 30px;
display: flex;
width: 72px;
padding: 3.141px 9.424px 3.141px 10px;
justify-content: center;
align-items: center;

color: #0E8E60;
font-size: 10.995px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.11px;
}
.stdbtn2{
    border-radius: 3.141px;
border: 0.785px solid #0E8E60;
background: #FFF;
height: 30px;

display: flex;
width: 72px;
padding: 3.141px 0px;
justify-content: center;
align-items: center;

color: #0E8E60;
font-size: 10.995px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.11px;
}
.p-button-icon{
    font-size: 10px;
}
.ttl3{
    color: #292D32;
text-align: center;
font-size: 14px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;
/* width: 50em !important; */
box-sizing: border-box;
/* border: 1px solid red; */
flex: 0 0 auto;
width: 18px;
}
.asgnimgbox{
    /* width: 18px; */
    /* border: 1px solid red; */
}

/* .asgnimgbox img{
    width: 100%;
} */
.ttl4{
    color: #000;
font-size: 11px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: normal;

}
.ttl0{
    color: #555;
font-size: 35px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
line-height: 72.404px;
}

.updbtn{
    color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 11.995px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.11px;
display: flex;
width: 72px;
padding: 3.141px 0px;
justify-content: center;
align-items: center;
gap: 7.853px;border-radius: 3.141px;
border: 0.785px solid #0E8E60;
background: #0E8E60;
}
.cd{
    height: calc(100vh - 80px); 
    overflow-y: scroll;
  }
  
  
  .cd::-webkit-scrollbar {
    width: 0.1em;
  }
  .hscrl::-webkit-scrollbar{
    width: 0.1em;
  }
.p-tabview-selected{
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
 

}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {

    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    /* background-color: #3f51b5; */
    background-color: #0E8E60;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: white;
color:  #0E8E60;
text-align: center;
}
/* .p-tabview-panel{
    border: 1px solid green;

} */
.yes{
    width: 33%;

}
.yes2{
    width: 24%;
}
.yes2ttl{
    color: #000;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: center;
}
.yesttl0{
    color: #000;
font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
overflow-x: elipsis;
}
.p-dialog-title{
    text-align: center;
}
.p-dialog p-dialog-titlebar-close{
    border-radius: 50px;
}
.p-dialog-titlebar-close{
    /* border: 2px solid green; */
    color: #0E8E60;
}
.p-dropdown .p-dropdown-label.p-placeholder {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Poppins";
    font-size: 10px;
    display: flex;
    align-items: center;
}
.p-dropdown .p-inputtext {
    border: 0 none;
    display: flex;
    align-items: center;
}
/* .custom-dialog .p-dialog-content {
    border-radius: 50px;
    overflow: hidden;
  }
  
  .custom-dialog .p-dialog-content .p-dialog-message {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  
  .custom-dialog .p-dialog-header {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
  } */
  